import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {MechanicalInventory, MechanicalItem} from "./mechanical-inventory";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, tap, throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MechanicalInventoryService {
  private apiUrl = `${environment.apiUrl}/mechanical_inventory`;
  private currentMechanicalInventory: MechanicalInventory = {
    mechanicalInventoryItems: [],
    cursor: '',
  };

  constructor(private httpClient: HttpClient) {
  }

  getMechanicalInventory(): Observable<MechanicalInventory> {
    return this.httpClient.get<MechanicalInventory>(this.apiUrl)
      .pipe(
        catchError(this.handleError),
        tap((data: MechanicalInventory) => {
          this.currentMechanicalInventory = data;
        })
      );
  }

  getMechanicalInventoryItem(id: string): MechanicalItem {
    const mechanicalInventoryItem = this.currentMechanicalInventory.mechanicalInventoryItems.find(p => p.id === id);
    if (!mechanicalInventoryItem) {
      throw new Error(`Could not find mechanical item with id ${id}`);
    }
    return mechanicalInventoryItem
  }

  addMechanicalInventoryItem(item: Partial<MechanicalItem>): Observable<Partial<MechanicalItem>> {
    return this.httpClient.post(`${this.apiUrl}/item`, {data: item}).pipe(
      catchError(this.handleError)
    );
  }

  deleteMechanicalInventoryItem(id: string): Observable<Partial<MechanicalItem>> {
    return this.httpClient.delete(`${this.apiUrl}/item/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  updateMechanicalInventoryItem(id: string, mechanicalInventoryItem: Partial<MechanicalItem>): Observable<Partial<MechanicalItem>> {
    return this.httpClient.put(`${this.apiUrl}/item/${id}`, {data: mechanicalInventoryItem}).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(e: HttpErrorResponse) {
    console.error(e.error);
    return throwError(() => e);
  }
}
