import {Component, signal} from '@angular/core';
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {Employee} from "../employee";
import {AuthService} from "../../auth/auth.service";
import {UtilsService} from "../../shared/utils.service";
import {EmployeeService} from "../employee.service";
import {TranslateService} from "../../shared/translate.service";

@Component({
  selector: 'app-employee-add',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './employee-add.component.html',
  styleUrl: './employee-add.component.css'
})
export class EmployeeAddComponent {

  constructor(private router: Router, private employeeService: EmployeeService, private authService: AuthService, private utilsService: UtilsService, private translateService: TranslateService) {
  }

  defautEmployeeValues = signal({
    groups: ["employee", "business_executor", "management"].map(v => {
      return {
        id: v,
        value: this.translateService.translateToFrench(v)
      }
    }),
    jobs: ["driver", "pdg", "dg", "manager", "controller", "mechanic"].map(v => {
      return {
        id: v,
        value: this.translateService.translateToFrench(v)
      }
    })
  })
  formErrors = signal({
    passwordDifferentFromConfirmationPassword: false
  })

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      if (f.value["employee-add-password"] !== f.value["employee-add-confirmationPassword"]) {
        this.formErrors.set({...this.formErrors(), passwordDifferentFromConfirmationPassword: true})
      } else {
        this.formErrors.set({...this.formErrors(), passwordDifferentFromConfirmationPassword: false})
        this.employeeService.addEmployee({
          ...this.utilsService.updateKeyNames(f.value, 'employee-add-'),
        } as Partial<Employee>).subscribe({
          next: () => {
            this.router.navigate(['/employees']);
          },
          error: (e) => {
            throw new Error(e);
          },
        })
      }
    } else {
      console.error(f)
    }
  }
}
