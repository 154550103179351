import {Component, OnInit, signal} from '@angular/core';
import {IncomeService} from "../income.service";
import {Income} from "../income";
import {RouterLink} from "@angular/router";
import {DatePipe, NgStyle} from "@angular/common";
import {AuthService} from "../../auth/auth.service";
import {EmployeeService} from "../../employee/employee.service";
import {VehicleService} from "../../vehicle/vehicle.service";
import {TranslateService} from "../../shared/translate.service";

@Component({
  selector: 'app-income-list',
  standalone: true,
  imports: [
    RouterLink,
    NgStyle,
    DatePipe
  ],
  templateUrl: './income-list.component.html',
  styleUrl: './income-list.component.css'
})
export class IncomeListComponent implements OnInit {
  hasManagementScope = signal(false)
  incomes = signal<Income[]>([]);

  constructor(private incomeService: IncomeService, private authService: AuthService, private employeeService: EmployeeService,
              private vehicleService: VehicleService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.incomeService.getAllIncomes().subscribe({
      next: (response) => {
        this.incomes.set(response.incomes.map((v) => {
          return {
            ...v,
            createdBy: this.employeeService.getEmployeeNameById(v.createdBy),
            type: this.translateService.translateToFrench(v.type)
          }
        }));
      },
      error: (e) => {
        throw new Error(e);
      },
    })
    this.hasManagementScope.set(this.authService.checkIfUserIsAllowed("group:business_executor"))
  }
}
