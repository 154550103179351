import {Component, OnInit, signal} from '@angular/core';
import {MechanicalItem} from "../mechanical-inventory";
import {MechanicalInventoryService} from "../mechanical-inventory.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {DatePipe, NgClass} from "@angular/common";
import {ExternalPartnerShopService} from "../../external-partner-shop/external-partner-shop.service";

@Component({
  selector: 'app-mechanical-inventory-item',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    NgClass
  ],
  templateUrl: './mechanical-inventory-item.component.html',
  styleUrl: './mechanical-inventory-item.component.css'
})
export class MechanicalInventoryItemComponent implements OnInit {
  isLoading = signal(false)
  mechanicalInventenryItem = signal<MechanicalItem>({} as MechanicalItem)

  constructor(
    private mechanicalInventoryService: MechanicalInventoryService,
    private externalPartnerShopService: ExternalPartnerShopService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    const item = this.mechanicalInventoryService.getMechanicalInventoryItem(this.route.snapshot.params['id'])
    this.mechanicalInventenryItem.set({
      externalPartnerShopName: this.externalPartnerShopService.getPartnerShop(item.externalPartnerShopId).name,
      ...item
    })
  }

  onDelete(): void {
    this.isLoading.set(true);
    this.mechanicalInventoryService.deleteMechanicalInventoryItem(this.mechanicalInventenryItem().id).subscribe({
      next: () => {
        this.router.navigate(['/inventaire']);
      },
      error: (e) => {
        throw new Error(e);
      },
    })
  }
}
