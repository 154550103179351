import {Component, OnInit, signal} from '@angular/core';
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {Outcome} from "../outcome";
import {UtilsService} from "../../shared/utils.service";
import {OutcomeService} from "../outcome.service";

@Component({
  selector: 'app-outcome-edit',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './outcome-edit.component.html',
  styleUrl: './outcome-edit.component.css'
})
export class OutcomeEditComponent implements OnInit {

  outcome = signal<Outcome>({} as Outcome);

  constructor(private outcomeService: OutcomeService, private utilsService: UtilsService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.outcome.set(<Outcome>this.outcomeService.getOutcomeById(this.route.snapshot.params['id']))
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.outcomeService.updateOutcome({
        ...this.utilsService.updateKeyNames(f.value, 'outcome-edit-'),
        id: this.outcome().id
      } as Partial<Outcome>).subscribe({
        next: () => {
          this.router.navigate(['/depenses']);
        },
        error: (e) => {
          throw new Error(e);
        },
      })
    }
  }

}
