<app-basepage>
  <h1 class="title is-3">Bilan</h1>

  <div class="column is-2">
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="summary-startDate" class="label">Date de début</label>
        <div class="control">
          <input
            class="input"
            type="date"
            min="1"
            name="summary-startDate"
            id="summary-startDate"
            ngModel
          />
        </div>
      </div>
      <div class="field">
        <label for="summary-endDate" class="label">Date de fin</label>
        <div class="control">
          <input
            class="input"
            type="date"
            min="1"
            name="summary-endDate"
            id="summary-endDate"
            ngModel
          />
        </div>
      </div>
      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
      </div>
    </form>
  </div>
  <h2 class="title is-4">Bilan Mensuel</h2>
  <section class="columns">
    <div class="column is-8">
      <table class="table is-bordered is-hoverable">
        <thead>
        <tr>
          <th></th>
          <th><abbr title="Revenu">Revenu</abbr></th>
          <th><abbr title="Dépense">Dépense</abbr></th>
          <th><abbr title="Total">Total</abbr></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th>Total</th>
          <td>{{ summary().totalIncomes }}</td>
          <td>-{{ summary().totalOutcomes }}</td>
          <td>{{ summary().balance }}</td>
        </tr>
        <tr>
          <th>Salaire</th>
          <td>0</td>
          <td>-{{ summary().totalSalaryOutcomes }}</td>
          <td>-{{ summary().totalSalaryOutcomes }}</td>
        </tr>
        <tr>
          <th>Global</th>
          <td>0</td>
          <td>-{{ summary().totalGlobalOutcomes }}</td>
          <td>-{{ summary().totalGlobalOutcomes }}</td>
        </tr>
        <tr>
          <th>Incidents</th>
          <td>0</td>
          <td>-{{ summary().totalIncidentOutcomes }}</td>
          <td>-{{ summary().totalIncidentOutcomes }}</td>
        </tr>
        <tr>
          <th>Pièces mécaniques</th>
          <td>0</td>
          <td>-{{ summary().totalMechanicalItemOutcomes }}</td>
          <td>-{{ summary().totalMechanicalItemOutcomes }}</td>
        </tr>

        <tr>
          <th>Véhicules</th>
          <td>{{ summary().totalIncomes }}</td>
          <td>{{ summary().totalVehicleOutcomes }}</td>
          <td>{{ summary().totalVehicleBalance }}</td>
        </tr>
          @for (item of summaryByTruck(); track $index) {
            <tr>
              <th>{{ item.id }}</th>
              <td>{{ item.totalIncomes }}</td>
              <td>-{{ item.totalOutcomes }}</td>
              <td>{{ item.balance }}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </section>

  <h2 class="title is-4">Bilan Hebdomadaire</h2>
  <section class="columns">
    <div class="column is-8">
      <table class="table is-bordered is-hoverable">
        <thead>
        <tr>
          <th></th>
          <th><abbr title="Revenu">Revenu</abbr></th>
          <th><abbr title="Dépense">Dépense</abbr></th>
          <th><abbr title="Total">Total</abbr></th>
        </tr>
        </thead>
        <tbody>
          @for (item of summaryByWeek(); track $index) {
            <tr>
              <th>Total semaine {{ $index + 1 }}</th>
              <td>{{ item.totalIncomes }}</td>
              <td>-{{ item.totalOutcomes }}</td>
              <td>{{ item.balance }}</td>
            </tr>
            @for (item of summaryByTruckByWeek()[$index]; track item.id) {
              <tr>
                <th>{{ item.id }}</th>
                <td>{{ item.totalIncomes }}</td>
                <td>-{{ item.totalOutcomes }}</td>
                <td>{{ item.balance }}</td>
              </tr>
            }
          }
        </tbody>
      </table>
    </div>
  </section>

</app-basepage>
