<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Modification d'une dépense</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">
      <div class="field">
        <label for="outcome-edit-description" class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            type="text"
            placeholder="La description des revenues"
            required
            name="outcome-edit-description"
            id="outcome-edit-description"
            ngModel
            [ngModel]="outcome().description"
            size="300"
            #outcomeEditDescription="ngModel"
          ></textarea>
        </div>
        @if (!outcomeEditDescription.valid && outcomeEditDescription.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La description est obligatoire</p>
        }
      </div>

      <div class="field">
        <div class="control">
          <label for="outcome-edit-isPaid" class="checkbox">
            <input
              ngModel
              name="outcome-edit-isPaid"
              id="outcome-edit-isPaid"
              type="checkbox"
              [ngModel]="outcome().isPaid"
            />
            La dépense a t-elle déjà été payé ?
          </label>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../..">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
