<section class="columns">
  <div class="column is-8">
    <table class="table is-bordered is-hoverable">
      <thead>
      <tr>
        <th><abbr title="Name">Nom</abbr></th>
        <th><abbr title="Prix neuf">Prix neuf</abbr></th>
        <th><abbr title="Prix d'occasion">Prix d'occasion</abbr></th>
        <th><abbr title="Commercant partenaires">Commercant partenaires</abbr></th>
      </tr>
      </thead>
      <tbody>
        @for (item of mechanicalInventoryItems(); track $index) {
          <tr>
            <th>
              <a [routerLink]="item.id" routerLinkActive="router-link-active"
                 [ngStyle]="{'pointerEvents': !hasEmployeeScope() ? 'none' : null}">
                {{ item.name }}</a>
            </th>
            <td>{{ item.newPrice }}</td>
            <td>{{ item.secondHandPrice }}</td>
            <td>{{ item.externalPartnerShopName }}</td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</section>
