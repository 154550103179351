import {Component, OnInit, signal, WritableSignal} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {VehicleService} from "../../vehicle/vehicle.service";
import {AuthService} from "../../auth/auth.service";
import {UtilsService} from "../../shared/utils.service";
import {OutcomeService} from "../outcome.service";
import {Outcome, OutcomeType} from "../outcome";
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {TranslateService} from "../../shared/translate.service";
import {EmployeeService} from "../../employee/employee.service";
import {Employee} from "../../employee/employee";
import {MechanicalInventoryService} from "../../mechanical-inventory/mechanical-inventory.service";
import {MechanicalItem} from "../../mechanical-inventory/mechanical-inventory";

@Component({
  selector: 'app-outcome-add',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './outcome-add.component.html',
  styleUrl: './outcome-add.component.css'
})
export class OutcomeAddComponent implements OnInit {

  defaultOutcomeAddValues: WritableSignal<Partial<Outcome>> = signal({
    currency: 'usd',
    vehicleIds: [],
    type: "vehicle",
    vehicleId: "",
    isPaid: true
  })
  defaultOutcomeTypes = signal(["global", "vehicle", "salary", "mechanical_item", "incident"].map(v => {
      return {
        id: v,
        value: this.translateService.translateToFrench(v)
      }
    })
  )
  currentUserInfo = signal({
    id: this.authService.sessionCredentials.userId,
    username: this.authService.sessionCredentials.username
  })
  defaultUsername = signal("")
  defaultEmployeesIds = signal<Partial<Employee>[]>([])
  defaultEmployeeId = signal("")
  defaultMechanicalInventoryItemId = signal<string>("")
  mechanicalInventory = signal<MechanicalItem[]>([])

  constructor(private router: Router, private outcomeService: OutcomeService, private vehicleService: VehicleService, private authService: AuthService, private utilsService: UtilsService, private translateService: TranslateService, private employeeService: EmployeeService, private mechanicalInventoryService: MechanicalInventoryService) {
  }

  ngOnInit() {
    this.vehicleService.getAllVehicles().subscribe({
      next: (response) => {
        this.defaultOutcomeAddValues.set({
          ...this.defaultOutcomeAddValues(),
          vehicleIds: response.vehicles.map(v => {
            return {
              name: v.name,
              id: v.id
            }
          }),
          vehicleId: response.vehicles.find(v => v)?.id
        });
        this.defaultUsername.set(this.employeeService.getEmployeeNameById(this.authService.sessionCredentials.userId || ""))
      },
      error: (e) => {
        throw new Error(e);
      },
    });
    this.employeeService.getAllEmployees().subscribe({
        next: (response) => {
          this.defaultEmployeesIds.set(response.users)
          this.defaultEmployeeId.set(response.users[0].id)
        },
        error: (e) => {
          throw new Error(e);
        },
      }
    )
    this.mechanicalInventoryService.getMechanicalInventory().subscribe({
      next: (response) => {
        this.mechanicalInventory.set(response.mechanicalInventoryItems);
        // this.defaultMechanicalInventoryItemId.set(response.mechanicalInventoryItems[0].id)
      },
      error: (e) => {
        throw new Error(e);
      },
    })
  }

  onSubmit(f: NgForm) {
    const vehicleIds = this.defaultOutcomeAddValues()!.vehicleIds
    const vehicleId = this.defaultOutcomeAddValues()!.vehicleId
    if (f.status === 'VALID' && vehicleIds) {
      this.outcomeService.addOutcome({
        ...this.utilsService.updateKeyNames(f.value, 'outcome-add-'),
        title: this.computeOutcomeTitle(this.defaultOutcomeAddValues().type, vehicleIds.find(v => v.id === vehicleId)?.name),
        createdBy: this.currentUserInfo().id,
        userId: this.defaultOutcomeAddValues().type === 'salary' ? f.value['outcome-add-employeeId'] : null
      } as Outcome).subscribe({
        next: () => {
          this.router.navigate(['/depenses']);
        },
        error: (e) => {
          throw new Error(e);
        },
      })
    }
  }

  private computeOutcomeTitle(outcomeType: OutcomeType | undefined, subject?: string): string {
    let title = `depense de type ${outcomeType}`
    if (outcomeType === "vehicle") {
      title += ` : ${subject}`
    }
    return title
  }
}
