<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Modification d'un outil mécanique</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">

      <div class="field">
        <label for="mechanical-inventory-item-edit-additionalComment" class="label">Détail additionel</label>
        <div class="control">
          <textarea
            class="textarea"
            type="text"
            placeholder="Commentaire sur la pièce"
            name="mechanical-inventory-item-edit-additionalComment"
            id="mechanical-inventory-item-edit-additionalComment"
            ngModel
            [ngModel]="mechanicalInventoryItem().additionalComment"
            size="300"
            rows="10"
          ></textarea>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
