<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'un employé</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">

      <div class="field">
        <label for="employee-edit-firstname" class="label">Prénom</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pierre"
            required
            name="employee-edit-firstname"
            id="employee-edit-firstname"
            [ngModel]="employee().firstname"
            size="300"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label for="employee-edit-lastname" class="label">Nom de famille</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="Pasteur"
            required
            [ngModel]="employee().lastname"
            name="employee-edit-lastname"
            id="employee-edit-lastname"
            size="300"
            disabled
          />
        </div>
      </div>


      <div class="field">
        <label for="employee-edit-job" class="label">Rôle</label>
        <div class="control">
          <div class="select">
            <select
              ngModel
              required
              name="employee-edit-job"
              id="employee-edit-job"
              [ngModel]="employee().job"
            >
              @for (item of defautEmployeeValues().jobs; track $index) {
                <option [value]="item.id">{{ item.value }}</option>
              }
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="employee-edit-group" class="label">Groupe</label>
        <div class="control">
          <div class="select">
            <select
              ngModel
              required
              name="employee-edit-group"
              id="employee-edit-group"
              [ngModel]="employee().group"
            >
              @for (item of defautEmployeeValues().groups; track $index) {
                <option [value]="item.id">{{ item.value }}</option>
              }
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="employee-edit-phoneNumber" class="label">Numéro de téléphone</label>
        <div class="control">
          <input
            class="input"
            type="tel"
            placeholder="e.g: +243 123 456 789"
            required
            name="employee-edit-phoneNumber"
            id="employee-edit-phoneNumber"
            ngModel
            [ngModel]="employee().phoneNumber"
            #employeeEditPhoneNumber="ngModel"
            pattern="^\+?[1-9][0-9]{7,14}$"
          />
        </div>
        @if (!employeeEditPhoneNumber.valid && employeeEditPhoneNumber.errors?.['required'] && f.submitted) {
          <p class="help is-danger">La numéro de téléphone est obligatoire</p>
        }
      </div>

      <div class="field">
        <label for="employee-edit-adress" class="label">Adresse de l'employée</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="9e rue Limite"
            name="employee-edit-adress"
            id="employee-edit-adress"
            ngModel
            [ngModel]="employee().adress"
            size="300"
          />
        </div>
      </div>

      <div class="field">
        <label for="employee-edit-additionalComment" class="label">Information Additionel</label>
        <div class="control">
          <textarea
            class="input"
            type="text"
            name="employee-edit-additionalComment"
            id="employee-edit-additionalComment"
            [ngModel]="employee().additionalComment"
            ngModel
            size="300"
          ></textarea>
        </div>
      </div>


      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
