import {Component, OnInit, signal} from '@angular/core';
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MechanicalItem} from "../mechanical-inventory";
import {MechanicalInventoryService} from "../mechanical-inventory.service";
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-mechanical-inventory-edit',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  templateUrl: './mechanical-inventory-edit.component.html',
  styleUrl: './mechanical-inventory-edit.component.css'
})
export class MechanicalInventoryEditComponent implements OnInit {
  mechanicalInventoryItem = signal<MechanicalItem>({} as MechanicalItem);

  constructor(
    private mechanicalInventoryService: MechanicalInventoryService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.mechanicalInventoryItem.set(this.mechanicalInventoryService.getMechanicalInventoryItem(this.route.snapshot.params['id']))
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.mechanicalInventoryService.updateMechanicalInventoryItem(this.mechanicalInventoryItem().id, {
        ...this.utilsService.updateKeyNames(f.value, 'mechanical-inventory-item-edit-'),
      } as Partial<MechanicalItem>).subscribe({
        next: () => {
          this.router.navigate(['/inventaire', this.mechanicalInventoryItem().id]);
        },
        error: (e) => {
          throw new Error(e);
        }
      });
    } else {
      // Handle invalid form submission
      this.utilsService.showFormErrors(f);
    }
  }
}
