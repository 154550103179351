import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {catchError, throwError} from "rxjs";
import {Summary, SummaryQuery} from "./summary";

@Injectable({
  providedIn: 'root'
})
export class SummaryService {

  constructor(private http: HttpClient) {
  }

  getSummary(summaryQuery?: SummaryQuery) {
    let url = `${environment.apiUrl}/summary`
    if (summaryQuery && summaryQuery.startDate && summaryQuery.endDate) {
      url = `${url}?start-date=${summaryQuery.startDate}&end-date=${summaryQuery.endDate}`;
    }
    return this.http
      .get<Summary>(url)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          // console.error(e.error);
          return throwError(() => e);
        })
      )
  }
}
