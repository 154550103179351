import {Component, OnInit, signal} from '@angular/core';
import {FormsModule, NgForm} from "@angular/forms";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {ExternalMechanic} from "../external-mechanic";
import {ExternalMechanicService} from "../external-mechanic.service";
import {AuthService} from "../../auth/auth.service";
import {TranslateService} from "../../shared/translate.service";
import {UtilsService} from "../../shared/utils.service";

@Component({
  selector: 'app-external-mechanic-edit',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink
  ],
  templateUrl: './external-mechanic-edit.component.html',
  styleUrl: './external-mechanic-edit.component.css'
})
export class ExternalMechanicEditComponent implements OnInit {
  externalMechanic = signal<ExternalMechanic>({} as ExternalMechanic);
  externalMechanicSpecialities = signal({
    "motor": false,
    "gearbox": false,
    "general": false,
    "air": false,
  })

  constructor(private router: Router, private externalMechanicService: ExternalMechanicService, private authService: AuthService, private translateService: TranslateService, private route: ActivatedRoute, private utilsService: UtilsService) {

  }

  ngOnInit(): void {
    this.externalMechanic.set(<ExternalMechanic>this.externalMechanicService.getExternalMechanicById(this.route.snapshot.params['id']))
    this.externalMechanicSpecialities.set({
      "motor": this.externalMechanic().specialities.includes("motor"),
      "gearbox": this.externalMechanic().specialities.includes("gearbox"),
      "general": this.externalMechanic().specialities.includes("general"),
      "air": this.externalMechanic().specialities.includes("air"),
    });
  }

  onSubmit(f: NgForm) {
    if (f.status === 'VALID') {
      this.externalMechanicService.updateExternalMechanic({
        ...this.utilsService.updateKeyNames(f.value, "external-mechanic-edit-"),
        specialities: this.formatSpecialities(f),
        additionalComment: f.value["external-mechanic-edit-additional-comment"],
        id: this.externalMechanic().id
      } as Partial<ExternalMechanic>).subscribe({
        next: () => {
          this.router.navigate(['/mecaniciens-externes']);
        },
        error: (e) => {
          throw new Error(e);
        },
      })
    } else {
      this.utilsService.showFormErrors(f);
    }
  }

  formatSpecialities(f: NgForm): (string | undefined)[] {
    return Object.entries(f.controls).map(([key, v]) => {
        if (key.includes('external-mechanic-edit-speciality-') && v.value) {
          return key.split('-').pop();
        }
        return undefined
      }
    ).filter(Boolean)
  }

}
