import {Component} from '@angular/core';
import {BasepageComponent} from "../basepage/basepage.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-mechanical-inventory',
  standalone: true,
  imports: [
    BasepageComponent,
    RouterOutlet
  ],
  templateUrl: './mechanical-inventory.component.html',
  styleUrl: './mechanical-inventory.component.css'
})
export class MechanicalInventoryComponent {

}
