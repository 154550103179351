<section class="columns is-centered">
  <div class="column is-11">
    <h1 class="title">Ajout d'une pièce</h1>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="on">

      <div class="field">
        <label for="mechanical-inventory-item-add-name" class="label">Nom</label>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="La nom de la pièce"
            required
            name="mechanical-inventory-item-add-name"
            id="mechanical-inventory-item-add-name"
            ngModel
            size="300"
            #mechanicalInventoryItemAddName="ngModel"
          />
        </div>
        @if (!mechanicalInventoryItemAddName.valid && mechanicalInventoryItemAddName.errors?.['required'] && f.submitted) {
          <p class="help is-danger">Le nom est obligatoire</p>
        }
      </div>


      <div class="field">
        <label for="mechanical-inventory-item-add-currency" class="label">Devise</label>
        <div class="control">
          <div class="select">
            <select
              [ngModel]="defaultMechanicalInventoryItemAddValues().currency"
              name="mechanical-inventory-item-add-currency"
              id="mechanical-inventory-item-add-currency"
            >
              <option value="eur">Euro</option>
              <option value="usd">Dollar</option>
              <option value="cdf">Franc CFA congolais</option>
            </select>
          </div>
        </div>
      </div>

      <div class="field">
        <label for="mechanical-inventory-item-add-newPrice" class="label">Prix neuf de la pièce</label>
        <div class="control">
          <input
            class="input"
            type="number"
            min="0"
            [ngModel]="0"
            placeholder="La prix neuf de la pièce"
            name="mechanical-inventory-item-add-newPrice"
            id="mechanical-inventory-item-add-newPrice"
            ngModel
          />
        </div>
      </div>

      <div class="field">
        <label for="mechanical-inventory-item-add-secondHandPrice" class="label">Prix d'occasion</label>
        <div class="control">
          <input
            class="input"
            type="number"
            min="0"
            [ngModel]="0"
            placeholder="La prix d'occasion de la pièce"
            name="mechanical-inventory-item-add-secondHandPrice"
            id="mechanical-inventory-item-add-secondHandPrice"
            ngModel
            size="300"
          />
        </div>
      </div>


      <div class="field">
        <label for="mechanical-inventory-item-add-externalPartnerShopId" class="label">Commercants partenaires</label>
        <div class="control">
          <div class="select">
            <select
              ngModel
              required
              name="mechanical-inventory-item-add-externalPartnerShopId"
              id="mechanical-inventory-item-add-externalPartnerShopId"
            >
              @for (item of currentPartnerShops(); track $index) {
                <option [value]="item.id">{{ item.name }}</option>
              }
            </select>
          </div>
        </div>
      </div>


      <div class="field">
        <label for="mechanical-inventory-item-add-additionalComment" class="label">Détail additionel</label>
        <div class="control">
          <textarea
            class="textarea"
            type="text"
            placeholder="Commentaire sur le mécanicien"
            name="mechanical-inventory-item-add-additionalComment"
            id="mechanical-inventory-item-add-additionalComment"
            ngModel
            size="300"
            rows="10"
          ></textarea>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button type="submit" class="button is-link">Valider</button>
        </div>
        <p class="control">
          <a routerLink="../">
            <button class="button">Retour</button>
          </a>
        </p>
      </div>
    </form>
  </div>
</section>
